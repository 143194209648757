<template>
  <va-card title="customer org kyc" class="logo_setting" >
    <form @submit.prevent="updateLogo">
    <va-select
      label="Customer Org Name *"
      v-model="stCustomer"
      :options="customerOtns"
      placeholder="Select Customer Org Name"
      textBy="customer_org_name"
      noClear/>
      <div class="upload_logo" v-for="(image,i) in logo" :key="i">
        <va-input
          :label="i=='aboutus'?'about us':i=='platform'?'Fav icon (16 X 16 px)':i"
          v-model="image.name"
          required="true"
          :disabled='true'
          placeholder="upload image">
          <section  slot="append" style="display: flex;" >
          <va-button small @click.prevent="removeLogo(i)" class='entypo entypo-cancel' color='danger' />
          <va-button small @click.prevent="selectImage(i)">
            UPLOAD
          </va-button>
          </section>
        </va-input>
        <input
          type="file" :id="i"
          @input.prevent="pickFile(i)" 
          style="display: none;" 
          accept="image/png">
          <div
            v-if="image.preview"
            class="imagePreviewWrapper"
            :style="{ 'background-image': `url(${image.preview})` }">
          </div>
      </div>
      <div class="d-flex justify--center mt-3">
        <va-button type="submit" class="my-0" :disabled="save_disabled">Save</va-button>
      </div>
    </form>
  </va-card>
</template>
<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'

Vue.use(vueResource)

export default {
  created () {
    this.getAllCustomer()
  },
  data () {
    return {
      customerOtns: [],
      stCustomer: '',
      save_disabled:true,
      logo: {
        brand:{
          preview:"",
          name:"",
          data:""
        },
        platform:{
          preview:"",
          name:"",
          data:""
        }
      }
    }
  },
  watch:{
    stCustomer(val){
      this.save_disabled = false
      if (!val){
        this.save_disabled = true
        this.logo = {
          brand:{
            preview:"",
            name:"",
            data:""
          },platform:{
            preview:"",
            name:"",
            data:""
          }
        }
        return
      }
      this.getCustomerKYC()
    },
    'logo.brand.preview'(image){
      if (!image){
        this.getCustomerKYC()
      }
    },
    'logo.platform.preview'(image){
      if (!image){
        this.getCustomerKYC()
      }
    }
  },
  methods: {
    getAllCustomer () {
      this.$http.get(config.menu.host + 'customer_org')
      .then(res => {
        res.data.forEach((customer, i) => {
          customer.id = i
          this.customerOtns.push(customer)
        })
      })
    },
    setBrand(response){
      let brand_location = response.data.brand_location.split("/")
      let brand_mime = brand_location[brand_location.length-1].split(".")[1]
      this.logo.brand.name = brand_location[brand_location.length-1]
      this.logo.brand.preview = 
      `data:image/${brand_mime};base64,${new Buffer.from(response.data.brand).toString('base64')}`
      this.logo.brand.data = this.b64toBlob(this.logo.brand.preview)
    },
    setPlatform(response){
      let platform_location = response.data.platform_location.split("/")
      let platform_mime = platform_location[platform_location.length-1].split(".")[1]
      this.logo.platform.name = platform_location[platform_location.length-1]
      this.logo.platform.preview = 
      `data:image/${platform_mime};base64,${new Buffer.from(response.data.platform).toString('base64')}`
      this.logo.platform.data = this.b64toBlob(this.logo.platform.preview)
    },
    setAboutus(response){
      let aboutus_location = response.data.aboutus_location.split("/")
      let aboutus_mime = aboutus_location[aboutus_location.length-1].split(".")[1]
      this.logo.aboutus.name = aboutus_location[aboutus_location.length-1]
      this.logo.aboutus.preview = 
      `data:image/${aboutus_mime};base64,${new Buffer.from(response.data.aboutus).toString('base64')}`
      this.logo.aboutus.data = this.b64toBlob(this.logo.aboutus.preview)
    },
    getCustomerKYC(){
      if (!this.stCustomer) return
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(`${config.menu.host}customer_kyc/${this.stCustomer.customer_org_id}`)
      .then(response => {
        loader.hide()
        this.setBrand(response)
        this.setPlatform(response)
        // this.setAboutus(response)
      })
      .catch(error => {
        loader.hide()
        console.log("dta",error)
        Vue.notify({text: error.body, type: 'error'})
      })
    },
    removeLogo(name){
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http
      .put(`${config.menu.host}customer_kyc/default/${this.stCustomer.customer_org_id}`,{
        name
      })
      .then(response => {
        loader.hide()
        this.getCustomerKYC()
        console.log("dta",2)
        Vue.notify({ text: response.body, type: 'success'})
      })
      .catch(error => {
        loader.hide()
        console.log("dta",3)
        Vue.notify({ text: error.body, type: 'error'})
      })
    },
    selectImage (key) {
      document.getElementById(key).click()
    },
    pickFile (key) {
      let input = document.getElementById(key)
      let files = input.files
      this.logo[key].name = files[0].name
      if (files && files[0]) {
      const imageReader = () => new Promise((resolve,_) => {
        let reader = new FileReader
        let image = new Image()
        reader.onload = e => {
          image.src = reader.result
          image.onload = () => {
            resolve({
              result:e.target.result,
              width : image.width,
              height: image.height
            })
          }
        }
        reader.readAsDataURL(files[0])
      })
      imageReader()
      .then(data => {
        if(key != 'platform'|| (data.width == 16 && data.height == 16)) {
          this.logo[key].preview = data.result
          this.logo[key].data = this.b64toBlob(data.result)
        } else {
          console.log("dta",4)
          Vue.notify({ text: "Platform Logo should be 16 X 16", type: 'error'})
        }
      })
      }
    },
    updateLogo() {
      if (!this.stCustomer) {
        console.log("dta",5)
        Vue.notify({text: "Please fill the form correctly",type: 'error'})
        return
      }
      let payload = new FormData()
      payload.append('brand',this.logo.brand.data,"brand")
      payload.append('platform',this.logo.platform.data,"platform")
      payload.append('aboutus',this.logo.brand.data,"aboutus")
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http
      .put(`${config.menu.host}customer_kyc/${this.stCustomer.customer_org_id}`, payload)
      .then(response => {
        loader.hide()
        this.getCustomerKYC()
        console.log("dta",6)
        Vue.notify({ text: response.body, type: 'success' })
      })
      .catch(error => {
        loader.hide()
        console.log("dta",7)
        Vue.notify({ text: error.body, type: 'error'})
      })
    },
    b64toBlob(logoData) {
      let block = logoData.split(";")
      let contentType = block[0].split(":")[1]
      let data = block[1].split(",")[1]
        contentType = contentType || ''
        let sliceSize = 512;

        let byteCharacters = atob(data)
        let byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize)

            let byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            let byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
        }
      let blob = new Blob(byteArrays, {type: contentType})
      return blob
    }
  }
}
</script>

<style scoped>
.imagePreviewWrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: white;
  border-radius: 10px;
  width: 300px;
  height: 300px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.imagePreviewWrapper:hover {
  box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.349);
}
</style>